<template>
  <header>
    <div class="containter">
      <h1><router-link class="navLogo" to="/">Het Appelhuis</router-link></h1>
      <button class="modalButton" id="show-modal" @click="showModal = true">Bekijk tarief</button>
    </div>
  </header>
  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal :show="showModal" @close="showModal = false">
      <template #header>
        <h1>Tarief</h1>
      </template>
      <template #body>
        <p class="modalText">Een afspraak bestaat uit twee kostenposten. De <b>voorrijkosten</b> en de <b>arbeidskosten</b>. De voorrijkosten worden berekend op basis van het aantal af te leggen kilometers*. Per kilometer wordt € 0,30 in rekening gebracht. De arbeidskosten zijn berekend per kwartier, met een minimum van een half uur. Wanneer gewenst kan ik, bij invullen van een probleemomschrijving, kostenloos een kostenschatting opsturen. Alle kosten zijn inclusief BTW.</p>
        <table>
          <thead>
            <tr>
              <th>Soort</th>
              <th>Kosten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Eerste half uur</td>
              <td>€ 35</td>
            </tr>
            <tr>
              <td>Elk kwartier daarna</td>
              <td>€ 17,50</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template #footer>
        <p>*Binnen Leiden, Voorschoten of Oegstgeest zullen geen voorrijkosten worden berekend.</p>
      </template>
    </modal>
  </Teleport>
</template>

<script>
import Modal from './CustomModal.vue'

export default {
  components: {
    Modal
  },
  data() {
    return {
      showModal: false
    }
  }
}
</script>

<style scoped>
header {
  z-index: 10;
  height: 10vh;
  background-color: var(--tertiary-color);
  border-bottom: 2px solid var(--primary-color);
  position: fixed;
  width: 100vw;
}

.containter {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modalText {
  text-align: justify;
}

table {
  border-collapse: collapse;
  width: 40%;
}

th,
td {
  border: 1px solid black;
  padding: 10px;
}

thead {
  text-align: left;
}

.modalButton {
  width: 20%;
  padding: 10px;
  background-color: var(--first-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 1.5vw;
  cursor: pointer;
  margin: 20px;
}

.modalButton:hover {
  background-color: var(--secondary-color);
  color: var(--fourth-color);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-self: center;
  align-items: center;
}

li {
  margin: 0 1rem;
}

.navLogo {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.navLink {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.25rem;
}

a:hover,
a:active,
.router-link-active {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>