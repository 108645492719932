<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-container">
                <div class="modal-header">
                    <slot name="header">default </slot>
                </div>

                <hr color="1C6758" noshade>
                
                <div class="modal-body">
                    <slot name="body">default body</slot>
                </div>
                
                <hr color="1C6758" noshade>

                <div class="modal-footer">
                    <slot name="footer">
                        default footer
                    </slot>
                    <button class="modal-default-button" @click="$emit('close')">Sluiten</button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        show: Boolean
    }
}
</script>

<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.modal-container {
    width: 50%;
    margin: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}

.modal-header {
    margin-top: 0;
    color: var(--secondary-color);
}

.modal-body {
    margin: 20px 0;
}

.modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-default-button {
  width: 30%;
  padding: 5px;
  background-color: var(--fourth-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 1.5vw;
  cursor: pointer;
  margin: 10px auto;
}

.modal-default-button:hover {
  background-color: var(--secondary-color);
  color: var(--fourth-color);
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>