import { createApp } from 'vue';

import { createRouter, createWebHistory } from "vue-router";

import App from './App.vue';
import OverMij from './components/OverMij.vue';
import HomePage from './components/HomePage.vue';

const app = createApp(App);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/over-mij', component: OverMij },
    ]
})


app.use(router);

app.mount('#app')
