<template>
  <article>
    <section id="het-appelhuis">
      <div id="appelhuisDiv" class="leftDiv">
        <img id="appelhuisLogo" src="../assets/images/Logo-with-text.svg" alt="Het Appelhuis Logo" />
        <h1 id="ondersteuningAanHuis">Apple ondersteuning aan huis</h1>
        <button id="contactButton" @click="neemContactOp">
          Neem contact op
        </button>
      </div>
      <div class="rightDiv">
        <img class="foto-gijs" src="../assets/images/Gijs.webp" alt="Gijs" />
      </div>
    </section>
    <section id="wie-ik-ben">
      <h1 class="generalH1">Wie ik ben</h1>
      <p id="wie-ik-ben-text">
        Het Appelhuis is opgericht om professioneel en doelgericht aan de wensen
        van klanten aan huis te kunnen voldoen. Mijn naam is Gijs, en als
        oud-Amac medewerker heb ik vele jaren als Apple Expert ervaring opgedaan
        om Apple-uitdagingen een oplossing te bieden. Ik merkte dat er veel
        vraag was naar oplossingsgericht werken en uitleg over de werking van
        producten en diensten. Om nu iedereen te kunnen helpen, ook mensen die
        niet of moeilijk het huis uit kunnen, ben ik voor mezelf begonnen.
      </p>
    </section>
    <section id="wat-ik-doe">
      <h1 class="generalH1">Wat ik doe</h1>
      <div id="cards">
        <div class="card">
          <h2 class="cardTitle">Apple, Wi-Fi & printers</h2>
          <ul>
            <li>iPhone, iPad, Mac en Apple Watch</li>
            <li>Wi-Fi en bedrade netwerken</li>
            <li>Printers</li>
          </ul>
        </div>
        <div class="card">
          <h2 class="cardTitle">iCloud, Mail, foto's en meer</h2>
          <ul>
            <li>Samenwerking tussen apparaten</li>
            <li>Apps en instellingen</li>
          </ul>
        </div>
        <div class="card">
          <h2 class="cardTitle">Op locatie</h2>
          <ul>
            <li>Leiden</li>
            <li>Voorschoten</li>
            <li>Rijnsburg</li>
            <li>...en omstreken</li>
          </ul>
        </div>
      </div>
    </section>
    <section id="contact">
      <h1 class="generalH1">Contact opnemen</h1>
      <form @submit="onSubmit" class="contactForm">
        <div class="formContainer">
          <div class="formLeftContainer">
            <div class="formInput" :class="{ invalid: klantNaamValidity === 'invalid' }">
              <label for="klantnaam">Naam: </label>
              <textarea name="from_name" id="klantnaam" v-model.trim="klantNaam" placeholder="Vul hier je naam in..."
                @blur="validateNameInput"></textarea>
            </div>
            <div class="formInput" :class="{ invalid: klantEmailValidity === 'invalid' }">
              <label for="email">Wat is je e-mail adres?</label>
              <textarea name="reply_to" id="email" v-model.trim="klantEmail" placeholder="Vul hier je email in"
                @blur="validateEmailInput"></textarea>
            </div>
            <div class="formInput" :class="{ invalid: klantAdresValidity === 'invalid' }">
              <label for="adres">Adres:</label>
              <textarea name="adres" id="adres" v-model.trim="klantAdres" placeholder="Vul hier je adres in..."
                @blur="validateAdresInput"></textarea>
            </div>
            <div class="formInput" :class="{ invalid: klantDatumValidity === 'invalid' }">
              <label for="datum">Wanneer komt het uit?</label>
              <textarea name="datum" id="datum" v-model.trim="klantDatum" placeholder="Hoe meer opties hoe beter..."
                @blur="validateDatumInput"></textarea>
            </div>
          </div>
          <div class="formRightContainer">
            <div class="formVraag" :class="{ invalid: klantVraagValidity === 'invalid' }">
              <label for="vraag">Vraag:</label>
              <textarea name="message" id="vraag" v-model.trim="klantVraag"
                placeholder="Vul hier de vraag of het probleem in..." @blur="validateVraagInput"></textarea>
            </div>
          </div>
        </div>
        <button class="formButton" type="submit" value="Send">
          Formulier versturen
        </button>
      </form>
    </section>
    <section id="tevreden">
      <div id="tevredenLeft" class="tevredenDiv">
        <div class="tevredenTextDiv">
          <h1 class="tevredenText">Tevreden?</h1>
          <h1 class="tevredenText">Niet tevreden?</h1>
        </div>
      </div>
      <div id="tevredenRight" class="tevredenDiv">
        <div class="tevredenTextDiv">
          <h1 class="tevredenText">Vertel het anderen</h1>
          <h1 class="tevredenText">Vertel het mij</h1>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      klantNaam: "",
      klantEmail: "",
      klantAdres: "",
      klantDatum: "",
      klantVraag: "",
      klantNaamValidity: "pending",
      klantEmailValidity: "pending",
      klantAdresValidity: "pending",
      klantDatumValidity: "pending",
      klantVraagValidity: "pending",
    };
  },
  methods: {
    neemContactOp() {
      var contact = document.getElementById("contact");
      contact.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    onSubmit(e) {
      e.preventDefault();
      if (
        this.klantNaamValidity == "valid" &&
        this.klantEmailValidity == "valid" &&
        this.klantAdresValidity == "valid" &&
        this.klantDatumValidity == "valid" &&
        this.klantVraagValidity == "valid"
      ) {
        this.sendEmail(e);
        alert("Aanvraag verstuurd!");
      } else {
        alert("Vul alle velden correct in");
      }
    },
    validateNameInput() {
      if (this.klantNaam === "") {
        this.klantNaamValidity = "invalid";
      } else {
        this.klantNaamValidity = "valid";
      }
    },
    validateEmailInput() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (
        this.klantEmail !== "" &&
        this.klantEmail.match(validRegex) &&
        this.klantEmail.includes(".")
      ) {
        this.klantEmailValidity = "valid";
      } else {
        this.klantEmailValidity = "invalid";
      }
    },
    validateAdresInput() {
      if (this.klantAdres === "") {
        this.klantAdresValidity = "invalid";
      } else {
        this.klantAdresValidity = "valid";
      }
    },
    validateDatumInput() {
      if (this.klantDatum === "") {
        this.klantDatumValidity = "invalid";
      } else {
        this.klantDatumValidity = "valid";
      }
    },
    validateVraagInput() {
      if (this.klantVraag === "") {
        this.klantVraagValidity = "invalid";
        return;
      } else {
        this.klantVraagValidity = "valid";
      }
    },
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_mdamqtg",
          "template_0tuapr9",
          e.target,
          "BCmnyW6Z2WR9eRkKz",
          {
            from_name: this.klantNaam,
            reply_to: this.klantEmail,
            place_to_live: this.klantAdres,
            datum: this.klantDatum,
            message: this.klantVraag,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      // this.klantNaam = ''
      // this.klantEmail = ''
      // this.klantAdres = ''
      // this.klantDatum = ''
      // this.klantVraag = ''
    },
  },
};
</script>

<style scoped>
section {
  height: 90vh;
}

/* ------------------------------------------ */
/* --------------- FIRST PAGE --------------- */
/* ------------------------------------------ */

#het-appelhuis {
  padding-top: 10vh;
  background-color: var(--tertiary-color);
  display: flex;
}

.leftDiv {
  width: 50vw;
  display: flex;
  justify-content: space-around;
}

.rightDiv {
  width: 50vw;
  border-left: 5px solid var(--primary-color);
}

.foto-gijs {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#appelhuisDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

#appelhuisLogo {
  padding-top: 5vw;
  padding-left: 5vw;
  width: 20vw;
}

#ondersteuningAanHuis {
  font-size: 3.5vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

#contactButton {
  width: 60%;
  padding: 20px;
  margin-left: 5vw;
  margin-bottom: 5vw;
  background-color: var(--tertiary-color);
  border: 5px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 2vw;
  cursor: pointer;
}

#contactButton:hover {
  background-color: var(--secondary-color);
  color: var(--fourth-color);
}

/* ------------------------------------------- */
/* --------------- WIE IK BEN ---------------- */
/* ------------------------------------------- */

.generalH1 {
  font-size: 3.5vw;
  padding: 50px;
}

#wie-ik-ben {
  background-color: var(--fourth-color);
}

#wie-ik-ben-text {
  margin: 5vw;
  /* margin: 5% 5% 10% 5%; */
  font-size: 2vw;
}

/* ------------------------------------------- */
/* --------------- WAT IK DOE ---------------- */
/* ------------------------------------------- */

#wat-ik-doe {
  background-color: var(--tertiary-color);
}

#cards {
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90vw;
}

.card {
  background-color: var(--fourth-color);
  border: 5px solid var(--primary-color);
  text-align: left;
  width: 25vw;
}

.cardTitle {
  padding: 2vw;
  font-size: 2.5vw;
}

li {
  font-size: 2vw;
}

/* ------------------------------------------- */
/* ------------- CONTACT OPNEMEN ------------- */
/* ------------------------------------------- */

#contact {
  background-color: var(--fourth-color);
}

.contactForm {
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.formContainer {
  display: flex;
  flex-direction: row;
  min-height: 40vh;
  width: 80vw;
  margin: auto;
}

.formLeftContainer,
.formRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.formInput {
  display: flex;
  flex-direction: column;
  height: 30%;
  width: 80%;
  margin: auto;
}

.formVraag {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  height: 100%;
}

label {
  padding-bottom: 5px;
  padding-top: 5px;
}

textarea {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  resize: none;
  padding: 5px;
}

.formButton {
  width: 30%;
  padding: 10px;
  background-color: var(--fourth-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 1.5vw;
  cursor: pointer;
  margin: 20px auto;
}

.formButton:hover {
  background-color: var(--secondary-color);
  color: var(--fourth-color);
}

.formInput.invalid textarea {
  border-color: red;
}

.formInput.invalid label {
  color: red;
}

.formVraag.invalid textarea {
  border-color: red;
}

.formVraag.invalid label {
  color: red;
}

/* ------------------------------------------- */
/* ----------------- TEVREDEN ---------------- */
/* ------------------------------------------- */

#tevreden {
  background-color: var(--tertiary-color);
  display: flex;
}

.tevredenDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vw;
}

#tevredenLeft {
  width: 50vw;
  text-align: right;
}

#tevredenRight {
  width: 50vw;
  text-align: left;
  border-left: 5px solid var(--primary-color);
}

.tevredenText {
  font-size: 4vw;
}

@media (max-width: 1080px) {
  h2 {
    font-size: 200%;
  }

  li {
    font-size: 150%;
  }
}

/* Tablets and under */
@media (max-width: 768px) {
  h1 {
    font-size: 20px;
  }

  li {
    font-size: 100%;
  }
}

/* Mobile */
@media (max-width: 500px) {
  #appelhuisLogo {
    padding-top: 10vw;
    width: 30vw;
  }

  #ondersteuningAanHuis {
    font-size: 1.5rem;
  }

  #contactButton {
    font-size: larger;
  }

  .generalH1 {
    font-size: 7vw;
    padding-bottom: 5vh;
  }

  #wie-ik-ben-text {
    font-size: 4.5vw;
  }

  .card {
    width: 27vw;
    border: 3px solid var(--primary-color);
  }

  .cardTitle {
    font-size: 3.5vw;
    height: 60px;
  }

  ul {
    padding-left: 4vw;
  }

  li {
    padding-left: 0px;
    font-size: 3.5vw;
  }

  .contactForm {
    height: 70vh;
  }

  .formInput,
  .formVraag {
    width: 90%;
  }

  .formButton {
    font-size: 4vw;
  }

  .tevredenText {
    font-size: 5vw;
  }
}
</style>